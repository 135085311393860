
// Packages
import Vue, { defineComponent } from 'vue';

// Types
import type { TranslateResult } from 'vue-i18n';

// Helpers
import {
  differenceInSeconds,
  parseISO,
} from '@white-label-helper/date-utilities';

// Constants
import {
  CookiesNames,
  MANAGE_BOOKING_KEYS,
  SECURITY_TOKEN_EXPIRATION,
} from '@white-label-configuration/constants';

// Stores
import {
  commitBlankModalData,
  commitModalData,
  readModalContent,
} from '@white-label-store/modal-data';

// Components
import SearchManageBookingResend from '../search-manage-booking-resend/search-manage-booking-resend.vue';
import SearchManageBookingSearch from '../search-manage-booking-search/search-manage-booking-search.vue';
import TabsController from '../tabs-controller/tabs-controller.vue';

// Icons
import IconAskMark from '@white-label-icon/icon-ask-mark';
import IconCloseCircle from '@white-label-icon/icon-close-circle';
import IconEmail from '@white-label-icon/icon-email';
import IconParking from '@white-label-icon/icon-parking';
import IconMail from '@white-label-icon/icon-mail';
import IconUser from '@white-label-icon/icon-user';

type AdditionalInfoKeys = 'search' | 'resend' | 'successInfo';

export default defineComponent({
  name: 'SearchManageBooking',

  components: {
    SearchManageBookingResend,
    SearchManageBookingSearch,
    TabsController,
  },

  data: () => ({
    // The current active tab
    action: MANAGE_BOOKING_KEYS.search,

    formStates: {
      searchIsTouched: false,
      resendIsTouched: false,
    },

    errorStates: {
      searchIsInvalid: false,
      resendIsInvalid: false,
    },
  }),

  computed: {
    /**
     * A list of tabs to display
     */
    tabs(): { key: MANAGE_BOOKING_KEYS; text: TranslateResult }[] {
      return [
        {
          key: MANAGE_BOOKING_KEYS.search,
          text: this.$t('bookings.manageBooking.tabs.search'),
        },
        {
          key: MANAGE_BOOKING_KEYS.resend,
          text: this.$t('bookings.manageBooking.tabs.resend'),
        },
      ];
    },

    additionalInfo(): Record<
      AdditionalInfoKeys,
      { icon: Vue.Component; text: TranslateResult }[]
    > {
      return {
        search: [
          {
            icon: IconUser,
            text: this.$t('bookings.manageBooking.additionalInfo.search.user'),
          },
          {
            icon: IconParking,
            text: this.$t(
              'bookings.manageBooking.additionalInfo.search.modify'
            ),
          },
          {
            icon: IconCloseCircle,
            text: this.$t('bookings.manageBooking.additionalInfo.search.close'),
          },
        ],
        resend: [
          {
            icon: IconEmail,
            text: this.$t(
              'bookings.manageBooking.additionalInfo.successInfo.email'
            ),
          },
          {
            icon: IconMail,
            text: this.$t(
              'bookings.manageBooking.additionalInfo.successInfo.mail'
            ),
          },
        ],
        successInfo: [
          {
            icon: IconAskMark,
            text: this.$t(
              'bookings.manageBooking.additionalInfo.resend.askMark'
            ),
          },
        ],
      };
    },

    searchAdditionalInfo():
      | { icon: Vue.Component; text: TranslateResult }[]
      | never[] {
      return this.errorStates.searchIsInvalid ? [] : this.additionalInfo.search;
    },

    resendAdditionalInfo():
      | { icon: Vue.Component; text: TranslateResult }[]
      | never[] {
      if (this.errorStates.resendIsInvalid) {
        return [];
      }

      if (this.formStates.resendIsTouched) {
        return this.additionalInfo.successInfo;
      }

      return this.additionalInfo.resend;
    },
  },

  mounted() {
    this.switchTab();
    this.handleSessionExpired();
    this.loadStoredModalData();
  },

  methods: {
    /** Switch the tab base on the URL search param `action` */
    switchTab() {
      const query = this.$route.query['action'];

      if (query === this.action) {
        // Skip and take no action
      } else if (typeof query === 'undefined' || Array.isArray(query)) {
        this.$router.push({ query: { action: this.action } });
      } else if (
        query.includes(MANAGE_BOOKING_KEYS.search) ||
        query.includes(MANAGE_BOOKING_KEYS.resend)
      ) {
        this.action = query as MANAGE_BOOKING_KEYS;
      } else {
        this.action = MANAGE_BOOKING_KEYS.search;
      }
    },

    /**
     * Listens for the event form the tab component. It's used to set the current selected tab
     * @param e - The key of the tabs that was selected
     * @listens input - event coming from `TabsController` a component
     */
    setSelectedTab(e: MANAGE_BOOKING_KEYS) {
      this.action = e;
      this.$router.push({ query: { action: this.action } });
    },

    validateForm(e: MANAGE_BOOKING_KEYS.resend) {
      // @ts-ignore
      this.$refs[e].$v.formData.email.$touch();
    },

    handleSessionExpired() {
      const manageBookingToken = this.$cookies.get(CookiesNames.manage);
      const manageBookingTokenDiff = differenceInSeconds(
        new Date(),
        parseISO(manageBookingToken?.timestamp)
      );

      // Check to see if manageBookingToken exceeds timestamp compare to now
      if (manageBookingTokenDiff > SECURITY_TOKEN_EXPIRATION) {
        // Remove manageBookingToken
        document.cookie = 'manageBookingToken=; Max-Age=-99999999;';

        commitModalData(this.$store, {
          header: this.$t('shared.modals.errors.sessionExpired.header'),
          body: '',
          btnText: this.$t('shared.buttons.close'),
        });
      }
    },

    loadStoredModalData() {
      const modalData = readModalContent(this.$store);
      if (!!modalData) {
        this.$openModal('GlobalModalError', modalData);
        commitBlankModalData(this.$store);
      }
    },
  },
});
