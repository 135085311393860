
import { PropType, defineComponent } from 'vue';
import type { Route } from 'vue-router';
import {
  typeOfState,
  typesOfElement,
} from '@white-label-configuration/constants';
import DotsLoader from '../dots-loader/dots-loader.vue';

/**
 * @deprecated Please use cavu-button if you are using this as a button
 */
export default defineComponent({
  name: 'ControlButton',

  components: {
    DotsLoader,
  },

  props: {
    id: {
      // id passed to selected type of element
      default: '',
      type: String as PropType<string>,
    },

    type: {
      default: undefined,
      type: String as PropType<'submit' | 'reset' | 'button' | undefined>,
    },

    typeOfElement: {
      // controls which html element will be used
      required: true,
      type: String as PropType<keyof typeof typesOfElement>,
      validator(val: keyof typeof typesOfElement) {
        return Object.keys(typesOfElement).includes(val);
      },
    },

    // type-of-element="button"
    buttonType: {
      // controls which color will have button
      required: true,
      type: String as PropType<keyof typeof typeOfState>,
      validator(val: keyof typeof typeOfState) {
        return Object.keys(typeOfState).includes(val);
      },
    },

    to: {
      // if typeOfElement === 'link' set route direction to it
      default: () => {},
      type: [String, Object] as PropType<string | Route>,
    },

    loading: {
      default: () => false,
      type: Boolean as PropType<boolean>,
    },

    disabled: {
      default: () => false,
      type: Boolean as PropType<boolean>,
    },

    isContentCentered: {
      default: () => false,
      type: Boolean as PropType<boolean>,
    },
  },

  computed: {
    getTypeOfStateClass(): string {
      if (!this.buttonType) {
        return '';
      }
      return `button-${this.buttonType}`;
    },

    getEnvClass(): string {
      const { NUXT_ENV_THEME } = process.env;
      if (!NUXT_ENV_THEME) {
        return '';
      }
      return `button-${NUXT_ENV_THEME}`;
    },

    getDisabledState(): string {
      if (!this.disabled) {
        return '';
      }
      return `button-${this.buttonType}--disabled`;
    },

    getJustifyClass(): string {
      if (!this.isContentCentered && !this.loading) {
        return 'justify-between';
      }
      return 'justify-center';
    },

    typeIsButton(): boolean {
      return this.typeOfElement === typesOfElement.button;
    },

    typeIsLink(): boolean {
      return this.typeOfElement === typesOfElement.link;
    },
  },

  mounted() {
    if (this.typeIsLink && !this.to) {
      throw new Error(
        'If typeOfElement === "link", prop "to" must contain value'
      );
    }

    this.$emit('mounted');
  },

  methods: {
    btnClick() {
      if (!this.loading) {
        this.$emit('btnClick');
      }
    },
  },
});
