
import { defineComponent, PropType } from 'vue';
import type VueI18n from 'vue-i18n';
export default defineComponent({
  name: 'ErrorMessage',

  components: {
    IconAlert: () => import('@white-label-icon/icon-alert'),
  },

  props: {
    html: {
      type: [String, Array] as PropType<
        string | string[] | VueI18n.TranslateResult
      >,
      default: '',
    },
    message: {
      type: String as PropType<string | VueI18n.TranslateResult>,
      default: '',
    },
  },
});
