// Packages
import { getStoreAccessors } from 'typesafe-vuex';

// Types
import type {
  RootState,
  MarketingPreferencesState,
  MarketingPreferencesActions,
  MarketingPreferencesMutations,
} from '@white-label-types/stores';

// Helpers
import { getUserMarketingSubscription } from '@white-label-helper/marketing-user';
import { getMarketingSettings } from '@white-label-helper/marketing-settings';
import { GetterTree } from 'vuex';

const state = (): MarketingPreferencesState => ({
  userMarketingSubscription: null,
  marketingPreferences: null,
  customText: null,
});

const getters: GetterTree<MarketingPreferencesState, unknown> = {
  userMarketingSubscription: (state) => state.userMarketingSubscription,
  marketingPreferences: (state) => state.marketingPreferences,
  marketingStrategy: (state) => state.marketingPreferences?.strategy,
  getEmail: (state) => state.userMarketingSubscription?.marketing_channels?.email,
  getSms: (state) => state.userMarketingSubscription?.marketing_channels?.sms,
}

const mutations: MarketingPreferencesMutations = {
  storeMarketingPreferences(state, payload) {
    state.marketingPreferences = payload;
  },
  storeUserMarketingSubscription(state, payload) {
    state.userMarketingSubscription = payload;
  },
}

const actions: MarketingPreferencesActions = {
  fetchMarketingPreferences({ commit }) {
    return getMarketingSettings()
      .then((data) => {
        commit('storeMarketingPreferences', data);
        return data;
      });
  },
  fetchUserMarketingSubscription({ commit }, email) {
    return getUserMarketingSubscription(email)
      .then((data) => {
        commit('storeUserMarketingSubscription', data);
        return data;
      });
  },
}

const { read, dispatch } = getStoreAccessors<MarketingPreferencesState, RootState>('marketingPreferences');
// Getters
export const readUserMarketingSubscription = read(getters['userMarketingSubscription']);
export const readMarketingPreferences = read(getters['marketingPreferences']);
export const readMarketingStrategy = read(getters['marketingStrategy']);

// Actions
export const dispatchUserMarketingSubscription = dispatch(actions.fetchUserMarketingSubscription);
export const dispatchMarketingPreferences = dispatch(actions.fetchMarketingPreferences);
export const readEmail = read(getters['getEmail']);
export const readSms = read(getters['getSms']);

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
