
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Backdrop',

  props: {
    hiddenBackdrop: {
      type: Boolean,
    },

    value: {
      type: Boolean,
    },
  },
});
