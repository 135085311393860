
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BTicketGuests',

  components: {
    IconUser: () => import('@white-label-icon/icon-user'),
  },

  props: {
    subItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
});
