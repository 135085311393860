
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { TranslateResult } from 'vue-i18n';

// Components
import CavuButton from '../cavu-button/cavu-button.vue';
import SearchManageBookingAdditionalInfo from '../search-manage-booking-additional-info/search-manage-booking-additional-info.vue';
import SearchManageBookingTab from '../search-manage-booking-tab/search-manage-booking-tab.vue';
import TextField from '../text-field/text-field.vue';
import TextFieldDate from '../text-field-date/text-field-date.vue';

// Helpers
import { email, required } from '@white-label-helper/vuelidate';
import { resendConfirmation } from '@white-label-helper/api-manage-booking';
import { add, guessTimezone } from '@white-label-helper/date-utilities';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';

// Constants
import {
  HTTP_STATUS_CODES,
  MANAGE_BOOKING_KEYS,
} from '@white-label-configuration/constants';

type LocaleFieldText = {
  error: TranslateResult;
  label: TranslateResult;
  placeholder: TranslateResult;
};

export default defineComponent({
  name: 'SearchManageBookingResend',

  components: {
    SearchManageBookingAdditionalInfo,
    SearchManageBookingTab,
    SubmitButton: CavuButton,
    TextField,
    TextFieldDate,
  },

  props: {
    isTouched: {
      type: Boolean,
      required: true,
    },

    additionalInfo: {
      type: Array as PropType<{ icon: string; text: TranslateResult }[]>,
      required: true,
    },
  },

  validations: {
    formData: {
      email: {
        required,
        email,
      },
    },
  },

  data() {
    return {
      loading: false,
      email: '',
      entryDate: '',
      serverData: {
        sentEmailsAmount: 0,
        customerEmail: '',
      },
      retryTimes: 0,
    };
  },

  computed: {
    doesResendConfirmationsExists(): boolean {
      return !!this.serverData.sentEmailsAmount;
    },

    formData(): { email: string; entryDate: string } {
      return {
        email: this.email,
        entryDate: this.entryDate,
      };
    },

    /**
     * Checks if the email looks to be a valid email string
     */
    emailHasErrors(): boolean {
      // @ts-ignore
      return this.$v.formData.email;
    },

    /**
     * Returns the translated messages for error, the text label and placeholder for email
     */
    emailTextField(): LocaleFieldText {
      return {
        error: this.$t('UI.formErrors.email'),
        label: this.$t('UI.form.email.label'),
        placeholder: this.$t('UI.form.email.placeholder'),
      };
    },

    /**
     * Returns the translated messages for error, the text label and placeholder for email
     */
    entryDateField(): Omit<LocaleFieldText, 'error'> {
      return {
        label: this.$t('UI.form.entryDate.label'),
        placeholder: this.$t('UI.form.entryDate.placeholder'),
      };
    },

    controlDate() {
      return {
        timeZone: guessTimezone(),
        minDate: new Date(),
        maxDate: add(new Date(), { years: 999 }),
      };
    },

    getSuccessMessage(): TranslateResult {
      return this.serverData.sentEmailsAmount === 1
        ? this.$t('bookings.resend.successOne')
        : this.$t('bookings.resend.successMany');
    },

    getButtonMessage(): TranslateResult {
      return !this.isTouched
        ? this.$t('bookings.button.resendConfirmation')
        : this.$t('bookings.button.tryAgain');
    },

    textFieldPrependIcon() {
      return () => import('@white-label-icon/icon-calendar');
    },
  },

  methods: {
    /**
     * Validating the form before sending the `resend` key off
     */
    submitForm() {
      this.$v.$touch();

      if (!this.$v.$error) {
        this.$v.$reset();
        this.success();
      }

      this.$emit('validateForm', MANAGE_BOOKING_KEYS.resend);
    },

    /**
     * Sends the request of to the server and sets cookie
     */
    async success() {
      this.loading = true;
      const { email, entryDate: entry_date } = this.formData;

      try {
        const data: { sent_emails_amount: number; customer_email: string } =
          await resendConfirmation({
            email,
            entry_date,
            product_type: getAppHeroProduct('parking')
          });

        this.serverData.sentEmailsAmount = data.sent_emails_amount;
        this.serverData.customerEmail = data.customer_email;
        this.$emit('update:isTouched', true);
      } catch (error: any) {
        const errorResponse = JSON.parse(error.message);

        if (errorResponse?.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
          this.serverData.sentEmailsAmount = 0;
          this.$emit('update:isTouched', true);
          this.$emit('update:isInvalid', true);
        } else {
          this.showRequestError();
        }
      } finally {
        this.loading = false;
      }
    },

    showRequestError() {
      const buttons = [
        {
          callback: () => {},
          btnText: this.$t('shared.buttons.close'),
        },
        {
          callback: () => {
            this.retryTimes += 1;
            this.success();
          },
          btnText: this.$t('bookings.resend.pleaseTryAgain.tryAgain'),
        },
      ];
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.header'),
        body: this.$t('shared.modals.errors.requestFailed.body'),
        btnText: this.$t('shared.buttons.close'),
        btnType: 'custom',
        buttons: this.retryTimes < 3 ? buttons : null,
      })
        .then((callback) =>
          typeof callback === 'function' ? callback() : callback
        )
        .catch((e) => {
          this.$sentry.capturemessage(e);
        });
    },
  },
});
