import { render, staticRenderFns } from "./error-message.vue?vue&type=template&id=8e1ed152&scoped=true"
import script from "./error-message.vue?vue&type=script&lang=ts"
export * from "./error-message.vue?vue&type=script&lang=ts"
import style0 from "./error-message.vue?vue&type=style&index=0&id=8e1ed152&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e1ed152",
  null
  
)

export default component.exports