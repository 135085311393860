
import { defineComponent, PropType } from 'vue';

import IconCheck from '@white-label-icon/icon-check';
import VueI18n from 'vue-i18n';

import ReceiptPageHeadPreloader from '../receipt-page-head-preloader/receipt-page-head-preloader.vue';

export default defineComponent({
  name: 'ManageBookingReceiptInfoHeader',

  components: {
    IconCheck,
    ReceiptPageHeadPreloader
  },

  props: {
    title: {
      type: String as PropType<string | VueI18n.TranslateResult>,
      default: '',
    },

    subtitle: {
      type: String as PropType<string | VueI18n.TranslateResult>,
      default: '',
    },

    contentLoading: {
      type: Boolean,
    },
  },
});
