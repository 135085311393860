import defaultAxios from 'axios'

export enum MarketingChannels {
  sms = 'SMS',
  email = 'EMAIL',
}

type MarketingResponse = {
  marketing_channels: MarketingChannels[]
  strategy: 'opt-in' | 'opt-out'
}

export const getMarketingSettings = async (): Promise<MarketingResponse> => {
  try {
    // Use base axios config with no auth to avoid CORS errors on public endpoint.
    const response = await defaultAxios({
      method: 'GET',
      baseURL: process.env['NUXT_ENV_PARTNER_CLOUDFRONT_URL'],
      url: `/amplify/${process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN']}.json`,
    })
    return response.data.marketing_preferences;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
}
