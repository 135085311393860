
// Packages
import { defineComponent, PropType } from 'vue';

// Helpers
import { email, required } from '@white-label-helper/vuelidate';
import { searchBooking } from '@white-label-helper/api-manage-booking';

// Constants
import {
  NAMED_ROUTES,
  HTTP_STATUS_CODES,
  MANAGE_BOOKING_KEYS,
  CookiesNames,
  SECURITY_TOKEN_EXPIRATION
} from '@white-label-configuration/constants';
// Types
import type { TranslateResult } from 'vue-i18n';
import type { CookieSerializeOptions } from 'cookie';

// Components
import CavuButton from '../cavu-button/cavu-button.vue';
import SearchManageBookingAdditionalInfo from '../search-manage-booking-additional-info/search-manage-booking-additional-info.vue';
import SearchManageBookingTab from '../search-manage-booking-tab/search-manage-booking-tab.vue';
import TextField from '../text-field/text-field.vue';

type LocaleFieldText = {
  error: TranslateResult;
  label: TranslateResult;
  placeholder: TranslateResult;
};

export default defineComponent({
  name: 'SearchManageBookingSearch',

  components: {
    SearchManageBookingAdditionalInfo,
    SearchManageBookingTab,
    SubmitButton: CavuButton,
    TextField,
  },

  props: {
    isTouched: {
      type: Boolean,
      required: true,
    },

    additionalInfo: {
      type: Array as PropType<{ icon: Vue.Component; text: TranslateResult }[]>,
      required: true,
    },
  },

  validations: {
    formData: {
      email: {
        required,
        email,
      },
      orderId: {
        required,
      },
    },
  },

  data() {
    return {
      email: '',
      orderId: '',
      loading: false,
    };
  },

  computed: {
    formData(): { email: string; orderId: string } {
      return {
        email: this.email,
        orderId: this.orderId,
      };
    },

    /**
     * Checks if the email looks to be a valid email string
     */
    emailHasErrors(): boolean {
      // @ts-ignore
      return this.$v.formData.email;
    },

    orderIdHasError(): boolean {
      // @ts-ignore
      return this.$v.formData.orderId;
    },

    /**
     * Returns the translated messages for error, the text label and placeholder for email
     */
    emailTextField(): LocaleFieldText {
      return {
        error: this.$t('UI.formErrors.email'),
        label: this.$t('UI.form.email.label'),
        placeholder: this.$t('UI.form.email.placeholder'),
      };
    },

    /**
     * Returns the translated messages for error, the text label and placeholder for order id
     */
    orderIdTextField(): LocaleFieldText {
      return {
        error: this.$t('UI.formErrors.orderId'),
        label: this.$t('UI.form.orderId.label'),
        placeholder: this.$t('UI.form.orderId.placeholder'),
      };
    },
  },

  mounted() {
    if (this.$route && this.$route.query) {
      if (this.$route.query.orderId) {
        this.orderId = String(this.$route.query.orderId);
      }
      if (this.$route.query.email) {
        this.email = String(this.$route.query.email);
      }
    }
    if (this.email && this.orderId) {
      this.submitForm();
    }
  },

  methods: {
    /**
     * Validating the form before sending the `resend` key off
     */
    submitForm() {
      this.$v.$touch();

      if (!this.$v.$error) {
        this.$v.$reset();
        this.success();
      }

      this.$emit('validateForm', MANAGE_BOOKING_KEYS.search);
    },

    /**
     * Sends the request of to the server and sets cookie
     */
    async success() {
      this.loading = true;
      const { email, orderId } = this.formData;

      try {
        const { token, expiration } = await searchBooking({
          email,
          reference_id: orderId,
        });
        const options = {
          maxAge: SECURITY_TOKEN_EXPIRATION,
          path: '/',
          sameSite: true,
        } as CookieSerializeOptions;

        if (process.env.NODE_ENV === 'production') {
          options.domain = process.env.NUXT_ENV_DOMAIN;
        }
        this.$cookies.set(
          CookiesNames.manage,
          { token, timestamp: new Date() },
          options
        );
        await this.$router.push(NAMED_ROUTES.manage_booking.default);
      } catch (error: any) {
        const errorResponse = JSON.parse(error.message);

        if (errorResponse?.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
          this.$emit('update:isTouched', true);
          this.$emit('update:isInvalid', true);
        } else {
          this.showRequestError();
        }
      } finally {
        this.loading = false;
      }
    },

    showRequestError() {
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.header'),
        body: this.$t('shared.modals.errors.requestFailed.body'),
        btnText: this.$t('shared.buttons.close'),
        btnType: 'custom',
      }).catch((e) => {
        this.$sentry.capturemessage(e);
      });
    },
  },
});
