import { getStoreAccessors } from 'typesafe-vuex';

export type State = {
  text: string,
};

const state = (): State => ({
  text: '',
});

const getters = {
  getMessage(state: State) {
    return state.text;
  }
}

const mutations = {
  updatePreloader(state: State, message: string) {
    state.text = message;
  },
};

export default {
  namespaced: true,
  preserveState: true,
  state,
  getters,
  mutations,
};

const { commit, read } = getStoreAccessors<State, State>('preloaderController');
export const readGetMessage = read(getters.getMessage);
export const commitUpdatePreloader = commit(mutations.updatePreloader);
