import { httpBooking } from '@white-label-configuration/http';
import { parseReceiptState } from '@white-label-helper/data-parsers-receipt'
import mock from './crossSellReceiptResponseMock.json';

// This request is used to get receipts in all flows:
// original receipt, manage booking receipt, cancellation receipt
export const getReceipt = (managementToken: string) => (
  httpBooking.get(
    '/booking/receipt/',
    { headers: { 'Management-Token': managementToken } },
  )
    .then((response) => parseReceiptState(response?.body?.data))
);

  // Used for testing cross-sell receipt page before the API is ready
  // TODO EM-102: Remove when `ECM_2_CROSS_SELL_POST_PURCHASE`
export const getCrossSellMockReceipt = async () => {
  // @ts-expect-error mock data is not typed
  return parseReceiptState(mock.data);
};

/**
 * Resend a confirmation email through the my account service.
 * Requires a valid auth token, order_id of the booking and
 * email to send receipt to.
 */
export const myAccountResendReceipt = (
  { token, order_id, email }:
    { token: string, order_id: number, email: string },
) => (
  httpBooking.post(
    'account/receipt/resend',
    {
      headers: { Authorization: token },
      body: { order_id, email },
    },
  )
    .then((response) => response.status)
);
