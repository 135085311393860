
import { defineComponent, PropType } from 'vue';
import type { TranslateResult } from 'vue-i18n';
import IconWrapper from '../icon-wrapper/icon-wrapper.vue';

export default defineComponent({
  name: 'SearchManageBookingAdditionalInfo',
  components: {
    IconWrapper,
  },
  props: {
    infoData: {
      type: Object as PropType<{ icon: Vue.Component; text: TranslateResult }>,
      required: true,
    },
  },
});
