
import { defineComponent } from 'vue';
import IconAskMark from '@white-label-icon/icon-ask-mark';
import IconSuccess from '@white-label-icon/icon-success';
import IconWrapper from '../icon-wrapper/icon-wrapper.vue';
import ModalCenter from '../modal-center/modal-center.vue';

export default defineComponent({
  name: 'ModalError',

  components: {
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconAskMark,
    IconSuccess,
    IconWrapper,
    ModalCenter,
  },

  props: {
    isSuccess: {
      type: Boolean,
      default: false,
    },

    isWarning: {
      type: Boolean,
      default: false,
    },

    isInfo: {
      type: Boolean,
      default: false,
    },

    propClass: {
      type: String,
      default: '',
    },

    btnType: {
      type: String,
      default: '',
    },

    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    envBasedClassName(): string {
      return `${process.env.NUXT_ENV_THEME}-modal-type-error`;
    },

    modalIconsModifiers(): {} | { width: number; height: number } {
      if (!this.isSuccess) {
        return {
          width: 20,
          height: 20,
        };
      }

      return {};
    },

    modalIconType(): string {
      if (this.isWarning) {
        return 'warning';
      }
      if (this.isInfo) {
        return 'info';
      }
      if (this.isSuccess) {
        return 'success';
      }
      return 'error';
    },
  },

  methods: {
    closeModal(): void {
      this.$emit('close-modal-error');
    },

    reloadPage(): void {
      this.$router.go(0);
    },
  },
});
