
import { defineComponent, PropType } from 'vue';
import RadioButton from '../radio-button/radio-button.vue';

export default defineComponent({
  name: 'RadioButtonGroup',

  components: {
    RadioButton,
  },

  props: {
    options: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    name: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    labels: {
      type: Object as PropType<{ [key: string]: string }>,
      default: () => {},
      required: true,
    },
  },

  data () {
    return {
      initial: this.value,
    };
  },

  methods: {
    onInput (option: string) {
      this.$emit('input', option);
    },
  },
});
