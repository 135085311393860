
import { defineComponent, PropType } from 'vue';

import americanExpressLogo from './assets/payment-logos/american_express.svg'
import discoverLogo from './assets/payment-logos/discover.svg'
import discoverWhiteLogo from './assets/payment-logos/discover_white.svg'
import mastercardLogo from './assets/payment-logos/mastercard.svg'
import mastercardWhiteLogo from './assets/payment-logos/mastercard_white.svg'
import paypalLogo from './assets/payment-logos/paypal.svg'
import visaLogo from './assets/payment-logos/visa.svg'

export type Logo = 'mastercard' | 'mastercard_white' | 'visa' | 'american_express' | 'discover' | 'discover_white' | 'paypal'

const importedLogos: Record<Logo, string> = {
  mastercard: mastercardLogo,
  mastercard_white: mastercardWhiteLogo,
  visa: visaLogo,
  american_express: americanExpressLogo,
  discover: discoverLogo,
  discover_white: discoverWhiteLogo,
  paypal: paypalLogo
}

export default defineComponent({
  name: 'PaymentLogos',

  props: {
    narrow: {
      type: Boolean,
      default: false,
    },

    logos: {
      type: Array as PropType<Logo[]>,
      default: () => [],
    },
  },
  data() {
    return {
      logosUrl: this.logos.map((logo) => ({
        name: logo,
        url: importedLogos[logo]
      }))
    };
  },
});
