/* eslint-disable @typescript-eslint/ban-ts-comment */
// Types
import type { ClientConfig } from '@white-label-types/parking-checkout';

// Services
import { httpBooking } from '@white-label-configuration/http';

import { PAYMENT_PROVIDERS } from '@white-label-configuration/constants';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { myApp } from '@white-label-plugin/global-app-context';

// @ts-ignore
httpBooking.onRequest((config) => {
  if (isBookingPortal) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${myApp.$auth?.user['https://user_metadata/channel_token']}`;
  }
});

type RequestBody = {
  MerchantId: string;
  ClientConfigStrategyType?: 'NewPaymentOnly';
}

const merchantId = process.env.NUXT_ENV_PAYMENT_MERCHANT_ID || '';
const token = process.env.NUXT_ENV_AFFILIATE_APPLICATION_TOKEN;
const newPaymentOnlyStrategy = process.env.NUXT_ENV_NEW_PAYMENT_ONLY_STRATEGY
  && process.env.NUXT_ENV_PAYMENT_PROVIDER === PAYMENT_PROVIDERS.STRIPE;

const requestBody: RequestBody = {
  MerchantId: merchantId,
};

if (newPaymentOnlyStrategy) {
  requestBody.ClientConfigStrategyType = 'NewPaymentOnly';
}

export const getClientConfig = (): Promise<ClientConfig['result']> => httpBooking.post('/payment-gateway/client-config', {
  headers: { 'Authorization': `Bearer ${token}` },
  body: requestBody,
}).then(({ body }: { body: ClientConfig }) => body.result);
