import { getStoreAccessors } from 'typesafe-vuex';
import { RootState } from '@white-label-types/stores';
import { TranslateResult } from 'vue-i18n';

export type State = {
    modalContent: {
        header: TranslateResult,
        body: TranslateResult,
        btnText: TranslateResult
    }
    hasData: boolean
    timestamp: number
};

const state = (): State => ({
    modalContent: {
        header: '',
        body: '',
        btnText: '',
    },
    hasData: false,
    timestamp: Infinity
});

const getters = {
    modalContent (state: State) {
        return state.hasData ? state.modalContent : null;
    },

    readTimestamp (state: State) {
        return state.timestamp;
    }
};

const mutations = {
    storeModalData( state: State, modalData: State['modalContent']) {
        state.modalContent.header = modalData.header;
        state.modalContent.body = modalData.body;
        state.modalContent.btnText = modalData.btnText;
        state.hasData = true;
        state.timestamp = Date.now()
    },

    clearModalData( state: State) {
        state.modalContent.header = '';
        state.modalContent.body = '';
        state.modalContent.btnText = '';
        state.hasData = false;
        state.timestamp = Infinity;
    }
};

export default {
    namespace: true,
    state,
    getters,
    mutations
}

const { commit, read } = getStoreAccessors<State, RootState>('modalData');

export const readModalContent = read(getters.modalContent);
export const readTimestamp = read(getters.readTimestamp);
export const commitModalData = commit(mutations.storeModalData);
export const commitBlankModalData = commit(mutations.clearModalData);
