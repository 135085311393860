
import { defineComponent, PropType } from 'vue';
import type { User } from '@white-label-types/parking-shared';

type ReorderedUserData = {
  first_name: string;
  last_name: string;
  phone: string | null;
  zipcode: string | null;
  inbound_flight: string | null;
  outbound_flight: string | null;
}

// Components
import ToggleContent from '../toggle-content/toggle-content.vue'

export default defineComponent({
  name: 'BTicketPersonalDetails',

  components: {
    ToggleContent,
  },

  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },

  computed: {
    reorderedUserData(): ReorderedUserData {
      return {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone.formatted_number,
        zipcode: this.user.zipcode,
        inbound_flight: this.user.inbound_flight,
        outbound_flight: this.user.outbound_flight,
      };
    },

    visibleUserDataEntries(): [string, string | null][] {
      const entries = Object.entries(this.reorderedUserData);
      return entries.filter(([label, value]) => this.isVisible(label, value));
    },

    licencePlateTranslation(): string {
      return this.$te(`receipt.ticketLabels.license_plate`)
        ? `receipt.ticketLabels.license_plate`
        : `manageBooking.ticketLabels.license_plate`;
    }
  },

  methods: {
    isVisible(label: string, value: string | null): boolean {
      return this.isLabelSupportedAndExists(label) && !!value;
    },

    isLabelSupportedAndExists(label: string): boolean {
      return (
        this.$te(`receipt.ticketLabels.${label}`) ||
        this.$te(`manageBooking.ticketLabels.${label}`)
      );
    },
    returnTranslatedLabel(label: string): string {
      return this.$te(`receipt.ticketLabels.${label}`)
        ? `receipt.ticketLabels.${label}`
        : `manageBooking.ticketLabels.${label}`;
    },
  },
});
