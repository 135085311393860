// Packages
import { RootState } from '@white-label-types/stores';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

// Helpers
import { getMarketingSettings } from '@white-label-helper/api-marketing-preference';

// Types
import type { MarketingPreferences } from '@white-label-types/parking-shared';

type MarketingPreferencesContext = ActionContext<State, RootState>;

export type State = {
  marketingPreferences: MarketingPreferences | null
}

const state = (): State => ({
	marketingPreferences: null
});

type Getters = {
  marketingStrategy (state: State): MarketingPreferences['strategy'] | undefined,
	marketingChannels (state: State): MarketingPreferences['marketing_channels'] | undefined
}

const getters: Getters = {
	marketingStrategy: (state: State) => state.marketingPreferences?.strategy,
	marketingChannels: (state: State) => state.marketingPreferences?.marketing_channels
}

const mutations = {
	storeMarketingPreferences(state: State, payload: MarketingPreferences) {
    state.marketingPreferences = payload;
  },
}

const actions = {
	fetchMarketingPreferences({commit}: MarketingPreferencesContext) {
    return getMarketingSettings()
      .then((data) => {
        commit('storeMarketingPreferences', data);
        return data;
      });
  },
}

const { read, dispatch } = getStoreAccessors<State, RootState>('marketingPreferences')

// Getters
export const readMarketingStrategy = read(getters.marketingStrategy);
export const readMarketingChannels = read(getters.marketingChannels);

// Actions
export const dispatchMarketingPreferences = dispatch(actions.fetchMarketingPreferences);

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
