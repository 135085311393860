
// Packages
import { defineComponent, PropType } from 'vue';

// Components
import IconCheck from '@white-label-icon/icon-check';

// Stores
import {
  readMarketingPreferences,
} from '@white-label-store/user-info';
import {
  readMarketingChannels,
  readMarketingStrategy,
} from '@white-label-store/marketing-preferences-account';

export default defineComponent({
  name: 'MarketingPreferences',

  components: {
    IconCheck,
  },

  props: {
    value: {
      type: Object as PropType<{email?: boolean, sms?: boolean}>,
      default: () => {},
    },
  },

  data() {
    return {
      isUserSubscribed: false,
    };
  },

  computed: {
    /**
     * Returns a list of all the channels that have been enabled by the partner
     */
    marketingChannels(): ReturnType<typeof readMarketingChannels> {
      return readMarketingChannels(this.$store);
    },

    /**
     * Get the marketing strategy
     * @example
     * opt-in: ticked = subscribed
     * opt-out: ticked = unsubscribed
     */
    marketingStrategy(): ReturnType<typeof readMarketingStrategy> {
      return readMarketingStrategy(this.$store);
    },

    /**
     * Get user's marketing settings
     */
    userMarketingPreferences(): ReturnType<typeof readMarketingPreferences> {
      return readMarketingPreferences(this.$store);
    },

    /**
     * Returns a boolean based on if marketing preferences: email has been enabled or not
     */
    emailEnabled(): boolean {
      return !!this.marketingChannels?.includes('email');
    },

    /**
     * Returns a boolean based on if marketing preferences: sms has been enabled or not
     */
    smsEnabled(): boolean {
      return !!this.marketingChannels?.includes('sms');
    },

    mobileSubscribeText(): string {
      return this.marketingStrategy === 'opt-in'
        ? this.$t('shared.marketingPreferences.optInGeneral')
        : this.$t('shared.marketingPreferences.optOutGeneral');
    },

    /**
     * Get the correct email checkbox value depending on user subscription status and marketing strategy
     * @example
     * opt-in: ticked = subscribed
     * opt-out: ticked = unsubscribed
     */
    emailTickValue(): boolean {
      return this.marketingStrategy === 'opt-in' || this.isUserSubscribed
        ? !!this.value?.email
        : !this.value?.email;
    },

    /**
     * Get the correct sms checkbox value depending on user subscription status and marketing strategy
     * @example
     * opt-in: ticked = subscribed
     * opt-out: ticked = unsubscribed
     */
    smsTickValue(): boolean {
      return this.marketingStrategy === 'opt-in' || this.isUserSubscribed
        ? !!this.value?.sms
        : !this.value?.sms;
    },
  },

  mounted() {
    this.isUserSubscribed = this.userMarketingPreferences
      !== null
      && Object.values(this.userMarketingPreferences).some((value) => !!value);
  },

  methods: {
    getMarketingChannelLabel(channel: 'email' | 'sms', isMobile: boolean): string {
      switch (channel) {
      case 'email':
        if (isMobile || this.isUserSubscribed) {
          return this.$t('shared.marketingPreferences.email');
        }

        return this.marketingStrategy === 'opt-in'
          ? this.$t('shared.marketingPreferences.optInEmail')
          : this.$t('shared.marketingPreferences.optOutEmail');

      case 'sms':
        if (isMobile || this.isUserSubscribed) {
          return this.$t('shared.marketingPreferences.sms');
        }

        return this.marketingStrategy === 'opt-in'
          ? this.$t('shared.marketingPreferences.optInSMS')
          : this.$t('shared.marketingPreferences.optOutSMS');

      default:
        return '';
      }
    },

    /**
     * Set the correct user subscription (email) value depending on checkbox value and marketing strategy
     */
    setTickValue(event: Event) {
      let checked = false;
      let channel = '';
      const element = event.target;
      if (element instanceof HTMLInputElement) {
        checked = element.checked;
        channel = element.dataset['channelName'] ?? 'Unknown channel name';
      }

      const subscribed = this.marketingStrategy === 'opt-in' || this.isUserSubscribed ? checked : !checked;
      this.$emit('marketingPreferences', {
        channel,
        subscribed,
      });
    },
  },
});
